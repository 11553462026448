@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Bodoni Std';
	src: url('../../dist/font/BodoniStd-Book.woff2') format('woff2'),
		url('../../dist/font/BodoniStd-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Dirk';
	src: url('../../dist/font/Dirk-Black.woff2') format('woff2'),
		url('../../dist/font/Dirk-Black.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

html {
	background: $sand;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: $display;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $black;
	max-height: 100vh;
	overflow: hidden;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

.hide,
.hidden {
	display: none !important;
}

.desktop {
	@include tablet-down {
		display: none !important;
	}
}

.mobile {
	@include tablet-up {
		display: none !important;
	}
}

.split {
	@include flex(column, initial, initial);

	.side {
		flex: 1;
	}

	@include tablet-up {
		@include flex(row, initial, initial);
	}
}

h1,
h2,
h3,
h4,
p,
ul {
	margin: 0;
}

h2 {
	font-size: 40px;
	color: $black;
	margin-bottom: 12px;
}

h3 {
	font-size: 32px;
	color: $black;
	margin-bottom: 12px;
}

.menu-trigger {
	order: 2;
	display: block;
	border: none;
	background-color: transparent;
	border: none;
	border-style: none;
	border: solid 2px transparent;
	border-top-color: black;
	border-bottom-color: black;
	position: relative;
	width: 40px;
	height: 25px;
	transition: all .4s;

	&:before,
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		top: calc(50% - 1px);
		left: 0;
		position: absolute;
		background-color: black;
		transition: all .4s;
	}

	&.active {
		border-color: transparent;
		position: relative;
		// top: 20px;
		// right: 10px;
		z-index: 999999999999;

		&::after {
			background-color: $orange;
			transform: rotate(45deg);
		}

		&::before {
			background-color: $orange;
			transform: rotate(-45deg);
		}
	}
}

.social {
	@include flex(row, center, center);
	gap: 10px;

	a {
		color: $black;
		font-size: 20px;

		@include hover-focus {
			color: $orange;
		}
	}
}

.header-logo {
	font-size: 42px;
	text-transform: uppercase;
	color: $orange;
}

.mfp-close-btn-in .mfp-close{
	color: $white;
	font-size: 40px;
	width: 30px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}

form {
	@include flex(row, initial, initial);
	overflow: hidden;
	gap: 10px;
	max-width: 180px;
	margin: 0 auto;

	label {
		position: absolute;
		left: -9999999999px;
	}

	.form-group {
		flex: 1;

		input {
			width: 100%;
			padding: 10px 2px 4px;
			font-size: 13px;
			background-color: transparent;
			border: solid 1px transparent;
			border-bottom-color: $black;
			font-family: $serif;
			color: $black;
			text-transform: capitalize;

			&::placeholder {
				color: $black;
			}

			@include hover-focus {
				outline: none;
				border-bottom-color: $orange;
			}
		}
	}

	button {
		flex: 1;
		max-width: max-content;
		padding: 10px 2px 4px;
		font-size: 13px;
		background-color: transparent;
		border: solid 1px transparent;
		border-bottom-color: $black;
		font-family: $serif;
		color: $black;
		text-transform: capitalize;

		@include hover-focus {
			outline: none;
			border-bottom-color: $orange;
		}
	}
}

.form-alert {
	text-align: center;
	font-size: 12px;
	margin: 0;
	color: $orange;
	display: none;

	&.error {
		margin-top: 10px;
	}
}

.btn {
	display: inline-block;
	padding: 10px 15px 8px;
	background-color: transparent;
	border: solid 2px $black;
	color: $black;
	font-family: $serif;
	font-size: 18px;
	@include flex(row, flex-start, center);
	gap: 10px;

	i {
		transform: translateY(-2px);
	}
}

header {

	.top {
		border-bottom: solid 1px $black;

		.container {
			padding: 0;

			.split {
				flex-direction: row;
				justify-content: space-between;

				.side {
					.header-logo {
						text-align: center;
					}

					padding: 10px;

					&.button {
						@include flex(column, center, center);
						border-left: solid 1px $black;
						max-width: max-content;
					}

					&.menu-container {
						background-color: $sand;
						display: block;
						position: fixed;
						top: 0;
						left: 0;
						width: 100%;
						height: 100vh;
						transition: all .4s;
						padding: 100px 0 200px;
						opacity: 0;
						pointer-events: none;
						
						&.active {
							z-index: 99999;
							opacity: 1;
							pointer-events: all;
						}

						.menu {
							@include flex(column, space-between, center);
							height: 100%;

							a {
								font-size: 32px;
								text-align: center;
								display: block;
								text-transform: capitalize;

								&.active {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}

	@include tablet-up {
		.top {
			border-bottom: solid 2px;

			.container {
				max-width: 100%;

				.split {
					.side {
						flex: 1;
						padding: 10px;

						.header-logo {
							font-size: 90px;
						}

						&:first-child {
							max-width: 620px;
							text-align: center;
						}

						&.menu-container {
							padding: 10px;
							border-left: solid 2px $black;
							opacity: 1;
							pointer-events: all;
							width: unset;
							height: unset;
							position: unset;

							.menu {
								@include flex(row, center, center);
								gap: 20px;

								a {
									flex: 1;
									font-size: 33px;

									@include hover-focus {
										color: $orange;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

main {
	@include tablet-down {
		max-height: calc(100vh - 62px);
		overflow-y: scroll;
	}

	.split {
		.side {
			flex: 1;

			&.image {
				max-width: 620px;

				img {
					width: 100%;
					height: calc(100vh - 110px);
					display: block;
					object-fit: cover;
				}
			}
		}
	}

	@include tablet-up {
		.split {
			.side {
				&.content {
					max-height: calc(100vh - 110px);
					overflow-y: scroll;
					-ms-overflow-style: none;
					/* IE and Edge */
					scrollbar-width: none;

					/* Firefox */
					&::-webkit-scrollbar {
						display: none;
					}

					&:last-child {
						border-left: solid 2px $black;
					}

					.content-bio {
						padding: 20px;

						.bio {
							h3 {
								text-align: center;
								margin: 20px 0 30px;
							}

							p {
								font-family: $serif;
								margin-bottom: 30px;
								line-height: 1.4;
							}
						}
					}
				}
			}
		}
	}
}

.section-heading {
	color: $black;
	text-align: center;
	margin-bottom: 20px;
	font-size: 38px;
}

#music,
#home {
	padding: 20px 0;

	.featured {
		padding: 0 10px;
		margin-bottom: 10px;

		.image {
			margin-bottom: 10px;

			a {
				display: block;
				position: relative;

				img {
					&.cover {
						display: block;
						width: 100%;
						height: auto;
					}

					&.badge {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 72px;
					}
				}
			}
		}

		.title {
			text-align: center;
			font-size: 32px;
		}
	}

	.songs {
		border-bottom: solid 2px $black;
		margin-bottom: 20px;

		.song {
			display: block;
			border-top: solid 2px $black;

			.split {
				@include flex(row, space-between, center);
				padding: 12px;

				.side {
					&.info {
						p {
							font-size: 16px;
						}

						span {
							font-size: 8px;
							display: block;
							font-family: $serif;
						}
					}

					&.icon {
						max-width: max-content;

						i {
							border: solid 2px $black;
							width: 24px;
							height: 24px;
							border-radius: 24px;
							padding: 0;
							line-height: 1;
							transition: all .4s;
							@include flex(column, center, center);
							font-size: 12px;

							&::before {
								transform: translateX(1px);
								color: $sand;
								-webkit-text-stroke: 2px $black;
							}
						}
					}
				}
			}

			@include hover-focus {
				.split {
					.side {
						&.icon {
							i {
								background-color: $black;
							}
						}
					}
				}
			}
		}
	}

	.link {
		display: block;
		text-align: center;
		font-size: 14px;
	}

	@include tablet-up {
		.featured {
			.image {
				margin-bottom: 20px;

				a {
					img {
						&.badge {
							width: 160px;
						}
					}
				}
			}

			.title {
				font-size: 42px;
				margin-bottom: 20px
			}

			.buttons {
				@include flex(row, space-between, initial);
				gap: 10px;
				max-width: 400px;
				margin: 0 auto 20px;
			}
		}

		.songs {
			.song {
				padding: 15px;

				.split {
					.side {
						&.info {
							p {
								font-size: 20px;
							}

							span {
								font-size: 14px;
							}
						}

						&.icon {
							i {
								width: 32px;
								font-size: 14px;
								height: 32px;
							}
						}
					}
				}
			}
		}

		.link {
			font-size: 18px;

			@include hover-focus {
				text-decoration: underline;
			}
		}
	}
}

#videos {
	padding: 20px 0;

	.wrapper {
		margin-bottom: 20px;

		.video {
			border-bottom: solid 2px $black;
			padding: 20px 10px;

			.split {
				@include flex(row, space-between, initial);
				gap: 10px;

				.side {
					flex: 1;

					&.thumb {
						flex: 2;

						video {
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: all .4s;
							filter: grayscale(1);
						}

						@include hover-focus {
							video {
								filter: none;
							}
						}
					}

					&.info {
						p {
							font-size: 14px;
						}

						span {
							font-size: 12px;
							display: block;
							margin-bottom: 20px;
						}

						a {
							font-size: 12px;
							font-family: $serif;
							display: block;
						}
					}
				}
			}
		}
	}

	.link {
		display: block;
		text-align: center;
		font-size: 14px;
	}

	@include tablet-up {
		.wrapper {
			.video {
				.split {
					.side {
						&.info {
							p {
								font-size: 30px;
							}

							span {
								font-size: 20px;
							}

							a {
								font-size: 16px;

								@include hover-focus {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}

		.link {
			font-size: 18px;

			@include hover-focus {
				text-decoration: underline;
			}
		}
	}
}

#tour {
	padding: 20px 0;

	#events {
		.event {
			border-bottom: solid 2px $black;
			padding: 10px;
			padding: 20px;
			@include flex(row, justify-content, center);
			gap: 10px;
			font-size: 16px;
			line-height: 1.2;
			text-align: left;

			.event-date {
				flex: 1;
				// max-width: 60px;
			}

			.event-info {
				flex: 2;

				.event-venue {
					font-family: $serif;
				}
			}

			.event-links {
				flex: 1;
				max-width: max-content;
			}
		}
	}

	@include tablet-up {
		#events {
			.event {
				font-size: 20px;

				.event-info {
					.event-venue {
						font-size: 17px;
					}
				}
			}
		}
	}
}

#bio {
	padding: 20px 15px;

	img {
		height: 240px;
		width: 100%;
		object-fit: cover;
	}

	h3 {
		text-align: center;
		margin: 20px 0;
		font-size: 24px;
		padding: 0 30px;
	}

	p {
		font-family: $serif;
		font-size: 18px;
		margin-bottom: 20px;
		line-height: 1.2;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include tablet-up {
		h3 {
			font-size: 32px;
			text-align: left;
			margin-bottom: 20px;
		}

		p {
			font-size: 20px;
			margin-bottom: 30px;
		}
	}
}

#footer {
	@include tablet-down {
		&.active {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 5;
		}
	}

	padding: 20px 0;

	.container {
		@include flex(column, initial, initial);
		gap: 20px;

		.newsletter {
			order: 1;
		}

		.social {
			order: 2;
		}

		.copyright {
			order: 3;
			text-align: center;

			.logos {
				@include flex(row, center, center);
				gap: 15px;
				margin-bottom: 10px;

				img {
					height: 14px;
				}
			}

			p {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 8px;
				line-height: 1.4;
				margin-bottom: 0;

				a {
					@include hover-focus {
						color: $orange;
					}
				}
			}
		}
	}

	@include tablet-up {
		.container {
			max-width: 100%;
			@include flex(row, space-between, center);

			.newsletter {
				order: 3;
				flex: 1;
			}

			.copyright {
				order: 2;
				flex: 3;
			}

			.socials {
				order: 1;
				flex: 1;
			}
		}
	}
}

main {
	section {
		display: none;

		&#home {
			display: block;
		}

		@include tablet-up {
			&#bio {
				display: none !important;
			}
		}
	}
}

#newsletter {
	p {
		font-size: 12px;
		margin-bottom: 10px;
		line-height: 1.4;
	}

	.disc {
		font-size: 10px;
		text-align: center;
		font-family: $serif;
		line-height: 1.2;
		margin-top: -15px;

		a {
			text-decoration: underline;

			@include hover-focus {
				color: $orange;
			}
		}
	}

	form {
		width: 100%;
		max-width: 100%;

		input {
			border-radius: 0;
			width: 100%;
			padding: 10px 2px 4px;
			font-size: 13px;
			background-color: transparent;
			border: solid 1px transparent;
			border-bottom-color: $black;
			font-family: $serif;
			color: $black;
			text-transform: capitalize;

			&::placeholder {
				color: $black;
			}

			@include hover-focus {
				outline: none;
				border-bottom-color: $orange;
			}
		}
	}
}

.seated-event-description-cells {
	font-size: 16px;

	.seated-event-venue-location {
		font-weight: 400;
	}
}

#tour {

	.seated-no-events {
		display: none;
	}

	.seated-events-table {
		border-top: none;
	}

	.seated-event-link {
		display: inline-block;
		padding: 10px 15px 8px;
		background-color: transparent;
		border: 2px solid #231f20;
		color: #231f20;
		font-family: Bodoni Std;
		font-size: 18px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		border-radius: 0;
		text-transform: capitalize !important;
		min-width: unset !important;
	}

	.seated-event-row {
		border-bottom: solid 2px $black;
	}

	// .seated-follow-box{
	// 	display: none;
	// }
}

.mfp-content {
	max-width: rem(800);
	width: 100%;
}

#popup-onload {
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		display: block;
		max-width: rem(800);

		img {
			width: 100%;
			margin: 0;
		}
	}
}

.popup {
	background-color: #000000ce;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	transition: all .4s;

	.container {
		height: 100vh;
		@include flex(column, center, center);

		.wrapper {
			background-color: $white;
			width: 100%;
			max-width: 400px;
			padding: 50px 20px 20px;
			position: relative;

			h2 {
				text-align: center;
				color: $orange;
			}

			.close {
				position: absolute;
				border: none;
				top: 0;
				right: 0;
				padding: 0;
				width: 40px;
				height: 40px;
				background-color: $black;
				color: $white;
				@include flex(column, center, center);
				margin-left: auto;

				i {
					font-size: 20px;
				}
			}
		}

		form {
			width: 100%;
			max-width: 100%;

			div {
				width: 100%;
			}

			input {
				width: 100%;
				padding: 10px;
				text-align: center;
				font-family: $serif;
			}

			button {
				background-color: $black;
				color: $white;
				width: 100%;
				text-align: center;
				max-width: 200px;
				@include flex(column, center, center);
				margin: 10px auto 0;
				font-family: $display;
			}
		}
	}

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	&.banner {
		.container {
			.wrapper {
				background-color: transparent;

				img {
					margin: 0 auto;
					width: 100%;
				}

				padding: 0;
				width: 630px;
				max-width: 100%;

				.close {
					background-color: transparent;
					top: 5px;
					right: 5px;
					font-size: 20px;

					@include tablet-up {
						top: 20px;
						right: 20px;
						font-size: 26px;
					}
				}

				.btn {
					position: absolute;
					bottom: 20px;
					width: 160px;
					left: calc(50% - 80px);
					text-align: center;
					background-color: $orange;
					color: $white;
					border-color: $orange;
					font-family: $display;
					@include flex(column, center, center);

					@include tablet-up {
						bottom: 40px;
						font-size: 20px;
					}
				}
			}
		}
	}
}